import React, { FC, memo } from 'react';
import { VerticalSpace, space } from 'ui/elements';
import { IRadioComponentProps } from 'types';
import { StyledRadio } from './RenderRadio.styled';

const RenderRadioComponent: FC<IRadioComponentProps> = ({ radioProps, onRadioChange }) => {
  return (
    <VerticalSpace size={parseInt(space.moderate)}>
      {radioProps.map((item) => (
        <StyledRadio value={item.id} key={item.key} onChange={onRadioChange}>
          {item.value}
        </StyledRadio>
      ))}
    </VerticalSpace>
  );
};

export const RenderRadio = memo(RenderRadioComponent);

import { CONFLICT_OF_INTEREST, Core, POST_CONFLICT_OF_INTEREST } from 'api/Core';
import { AxiosPromise } from 'axios';
import { IConflictOfInterestResponse } from 'types';

interface GetConflictOfInterestFields {
  (): AxiosPromise<IConflictOfInterestResponse>;
}

interface SubmitConflictOfInterest {
  (formData: FormData): AxiosPromise<unknown>;
}

export class ConflictOfInterest extends Core {
  getConflictOfInterestFields: GetConflictOfInterestFields = async () =>
    this.get(CONFLICT_OF_INTEREST());
  submitConflictOfInterest: SubmitConflictOfInterest = async (formData) =>
    this.post(POST_CONFLICT_OF_INTEREST(), formData);
}

export const conflictOfInterest = new ConflictOfInterest();

import React, { memo, VFC } from 'react';
import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslate } from 'translations';
import { ArrowRightOutlined } from '@ant-design/icons';
import { UseModalContext } from 'app';
import { IConflictOfInterestResponse } from 'types';
import { QuestionLayout } from '../QuestionLayout';
import { getQuestionItems, typeOfDisclosure } from '../../Constants';
import {
  StyledConflictDisclosure,
  StyledConflictOfInerestContainer,
  StyledConflictOfInerestHeader,
  StyledConflictOfInterestFooter,
  StyledConflictOfInterestFormCheckbox,
  StyledDescription,
  StyledFooterNote,
} from './ModelContainer.styled';

const ModalContainerComponent: VFC<IConflictOfInterestResponse> = ({
  membersOfFamily,
  typeOfReporting,
}) => {
  const { t } = useTranslate();
  const [form] = useForm();
  const questionItems = getQuestionItems(t, membersOfFamily, typeOfReporting);
  const { onSubmitConflictOfInterest } = UseModalContext();
  return (
    <StyledConflictOfInerestContainer>
      <StyledConflictOfInerestHeader>
        {t('conflict.of.interest.disclosure.heading')}
      </StyledConflictOfInerestHeader>
      <StyledDescription>{t('conflict.of.interest.disclosure.description')}</StyledDescription>
      <Form form={form} onFinish={onSubmitConflictOfInterest} layout="vertical">
        {questionItems.map((item, index) => (
          <QuestionLayout key={`questionItem${index}`} {...item} t={t} form={form} />
        ))}
        <Form.Item
          name="confirm"
          valuePropName="checked"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <StyledConflictOfInterestFormCheckbox>
            {t('conflict.of.interest.checkbox')}
          </StyledConflictOfInterestFormCheckbox>
        </Form.Item>
        <Form.Item shouldUpdate>
          {({ getFieldValue, getFieldsError }) => (
            <StyledConflictDisclosure
              disabled={
                !getFieldValue('confirm') ||
                getFieldValue('typeOfDisclosure') === undefined ||
                !getFieldValue('typeOfReporting') ||
                (getFieldValue('typeOfDisclosure') === typeOfDisclosure.YES &&
                  !getFieldValue('explaination')) ||
                getFieldsError().some(({ errors }) => errors.length)
              }
              htmlType="submit"
            >
              {t('submit')}
              <ArrowRightOutlined />
            </StyledConflictDisclosure>
          )}
        </Form.Item>
      </Form>
      <StyledConflictOfInterestFooter>
        <StyledFooterNote>{t('conflict.of.interest.footer.note')}</StyledFooterNote>
      </StyledConflictOfInterestFooter>
    </StyledConflictOfInerestContainer>
  );
};

export const ModalContainer = memo(ModalContainerComponent);

import { IConflictOfInterestFormFields } from 'types';

export enum EModalToOpen {
  NOT_ANY,
  CODE_OF_ETHICS,
  CONFLICT_OF_INTEREST,
}

export interface IModalContext {
  openModal: EModalToOpen;
  onSubmitCodeOfEthics: ({ confirm }: { confirm: boolean }) => void;
  isSubmitEthicsCodeConfirmationLoading: boolean;
  onSubmitConflictOfInterest: (formFields: IConflictOfInterestFormFields) => void;
  isSubmitConflictOfInterestLoading: boolean;
}

import { Button, Form, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';
import { colors } from 'ui/elements';

const { Paragraph } = Typography;

export const StyledQuestionText = styled(Paragraph)`
  font-size: 16px;
  font-weight: 700;
  color: ${colors.textColorSecondary};
`;

export const StyledFileUploadWrapper = styled(Form.Item)<{ $disabled: boolean }>`
  ${({ $disabled }) =>
    $disabled &&
    `:hover  {
      .ant-upload-span {
        .ant-upload-list-item-card-actions-btn {
          opacity: 1;
          svg {
              color:  ${colors.lightRed};
          }
        }
      }
  }`}
`;

export const ConflictOfInterestDragger = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  gap: 6px;
  radius: 4px;
  width: 100%;
  border: none;
  background: none !important;
`;
export const StyledDragIcon = styled(Paragraph)`
  display: flex;
  margin-bottom: 0 !important;

  .anticon {
    font-size: 20px;
  }
`;

export const StyledDragWatermark = styled(Paragraph)`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: ${colors.primaryColor};
  margin: 0 !important;
`;

export const StyledLabel = styled.span`
  color: ${colors.textColorSecondary};
`;

export const StyledTextArea = styled(TextArea)`
  width: 830px;
  border: 1px;
  .ant-input {
    height: 80px !important;
    radius: 4px;
  }
`;

import React, { FC } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { TranslationsProvider } from 'translations';
import { AuthProvider } from 'authentication';
import { translations } from 'namespace';
import { StoreProvider, store } from 'store';
import { ServiceProvider, ModalContextProvider } from 'app';

const { REACT_APP_ENV } = process.env;

const REACT_QUERY_CLIENT_DEFAULT_CONFIG = {
  defaultOptions: {
    queries: {
      retry: 1,
    },
  },
};

const queryClient = new QueryClient(REACT_QUERY_CLIENT_DEFAULT_CONFIG);

export const AppProviders: FC = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <StoreProvider store={store}>
          <TranslationsProvider messages={translations}>
            <ModalContextProvider>
              <ServiceProvider>{children} </ServiceProvider>
            </ModalContextProvider>
          </TranslationsProvider>
        </StoreProvider>
      </AuthProvider>

      {REACT_APP_ENV === 'local' && ReactQueryDevtools ? <ReactQueryDevtools /> : null}
    </QueryClientProvider>
  );
};

import { PERSIST_QUERY_CONFIG, QUERIES } from 'api/constants';
import { useQuery } from 'react-query';
import { conflictOfInterest } from '../ConflictOfInterest';

export const useGetConflictOfInerest = (isModalOpen: boolean) =>
  useQuery(
    [QUERIES.conflictOfInerest],
    async () => {
      const { data } = await conflictOfInterest.getConflictOfInterestFields();
      return data;
    },
    {
      enabled: isModalOpen,
      ...PERSIST_QUERY_CONFIG,
    }
  );

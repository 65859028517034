import React, { FC, memo, useCallback } from 'react';
import { Form, Radio, RadioChangeEvent } from 'antd';
import { RestrictedUpload } from 'ui/components/RestrictedUpload';
import { FolderOpenOutlined } from '@ant-design/icons';
import { IQuestionLayoutProps } from 'types';
import { RenderRadio } from '../RadioButtons';
import { ALLOWED_FILE_EXT, typeOfDisclosure } from '../../Constants';
import {
  ConflictOfInterestDragger,
  StyledDragIcon,
  StyledDragWatermark,
  StyledFileUploadWrapper,
  StyledLabel,
  StyledQuestionText,
  StyledTextArea,
} from './QuestionLayout.styled';

const QuestionLayoutComponent: FC<IQuestionLayoutProps> = ({
  questionHeader,
  radioFieldName,
  radioKey,
  radioProps,
  radioGroupName,
  actualRadioKey,
  uploadFormFieldName,
  restrictedUploadKey,
  DragWatermarkKey,
  showExplain = false,
  t,
  form,
}) => {
  const onRadioChange = useCallback(
    (e: RadioChangeEvent) => {
      if (e.target.value === '4') {
        form.setFields([{ name: 'explaination', value: undefined }]);
      }
    },
    [form]
  );
  return (
    <React.Fragment>
      <Form.Item
        name={radioFieldName}
        key={radioKey}
        label={<StyledQuestionText>{questionHeader}</StyledQuestionText>}
      >
        <Radio.Group name={radioGroupName}>
          <RenderRadio
            radioProps={radioProps}
            key={actualRadioKey}
            onRadioChange={showExplain ? onRadioChange : undefined}
          />
        </Radio.Group>
      </Form.Item>
      {showExplain && (
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) =>
            getFieldValue('typeOfDisclosure') === typeOfDisclosure.YES && (
              <Form.Item
                name="explaination"
                label={<StyledLabel>{t('conflict.of.interest.explanation')}</StyledLabel>}
              >
                <StyledTextArea
                  placeholder={t('conflict.of.interest.explanation.placeholder')}
                  showCount
                  maxLength={1024}
                ></StyledTextArea>
              </Form.Item>
            )
          }
        </Form.Item>
      )}
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => (
          <StyledFileUploadWrapper
            name={uploadFormFieldName}
            label={<StyledLabel>{t('conflict.of.interest.disclosure.fileupload')}</StyledLabel>}
            shouldUpdate
            $disabled={
              getFieldValue(uploadFormFieldName) !== undefined &&
              getFieldValue(uploadFormFieldName).length > 0
            }
          >
            <RestrictedUpload
              key={restrictedUploadKey}
              listType="text"
              showUploadList={true}
              multiple={false}
              maxAttachmentCount="1"
              allowedFileExtensions={ALLOWED_FILE_EXT}
            >
              <ConflictOfInterestDragger
                disabled={
                  getFieldValue(uploadFormFieldName) !== undefined &&
                  getFieldValue(uploadFormFieldName).length > 0
                }
              >
                <StyledDragIcon>
                  <FolderOpenOutlined />
                </StyledDragIcon>
                <StyledDragWatermark key={DragWatermarkKey}>
                  {t('conflict.of.interest.disclosure.dragfiles')}
                </StyledDragWatermark>
              </ConflictOfInterestDragger>
            </RestrictedUpload>
          </StyledFileUploadWrapper>
        )}
      </Form.Item>
    </React.Fragment>
  );
};

export const QuestionLayout = memo(QuestionLayoutComponent);

import React, { FC, useMemo } from 'react';
import { UseModalContext } from 'app';
import { EModalToOpen, IConflictOfInterestResponse } from 'types';
import { useGetConflictOfInerest } from 'api';
import { FullWidthSpin } from 'ui/elements';
import { Empty } from 'antd';
import { useTranslate } from 'translations';
import { StyledConflictOfInterestPopup } from './ConflictOfInterest.styled';
import { ModalContainer } from './components';

export const ConflictOfInterest: FC = () => {
  const { openModal, isSubmitConflictOfInterestLoading } = UseModalContext();
  const { t } = useTranslate();
  const {
    data: ConflictOfInterestFieldsData,
    isLoading: isConflictOfInerestLoading,
    isError: isEthicsCodeContentError,
  } = useGetConflictOfInerest(openModal === EModalToOpen.CONFLICT_OF_INTEREST);

  const GetModalChildComponent = useMemo(() => {
    switch (true) {
      case isConflictOfInerestLoading || isSubmitConflictOfInterestLoading:
        return <FullWidthSpin />;
      case isEthicsCodeContentError:
        return <Empty style={{ width: '100%' }} description={t('something.went.wrong')} />;
      case !isConflictOfInerestLoading &&
        !isEthicsCodeContentError &&
        ConflictOfInterestFieldsData !== undefined:
        return (
          <ModalContainer {...(ConflictOfInterestFieldsData as IConflictOfInterestResponse)} />
        );
      default:
        return <FullWidthSpin />;
    }
  }, [
    ConflictOfInterestFieldsData,
    isConflictOfInerestLoading,
    isEthicsCodeContentError,
    isSubmitConflictOfInterestLoading,
    t,
  ]);

  return (
    <StyledConflictOfInterestPopup
      visible={openModal === EModalToOpen.CONFLICT_OF_INTEREST}
      getContainer={false}
      footer={null}
      closable={false}
      centered
    >
      {GetModalChildComponent}
    </StyledConflictOfInterestPopup>
  );
};

import { Button, Checkbox, Typography } from 'antd';
import styled from 'styled-components';
import { colors, TitleH3 } from 'ui/elements';

const { Paragraph } = Typography;

export const StyledConflictOfInerestContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  .ant-form-vertical {
    width: 100%;
  }

  .ant-upload-select {
    width: 100%;

    .ant-upload {
      width: 100%;
      position: relative;
      height: 90px;
      border: 1px dashed ${colors.borderGreySecond};
      background: none !important;
      display: block;
      cursor: pointer;
    }
  }
`;

export const StyledConflictOfInerestHeader = styled(TitleH3)`
  margin: 0;
  font-size: 20px !important;
  font-weight: 500 !important;
  color: ${colors.textColorSecondary} !important;
`;

export const StyledDescription = styled(Paragraph)`
  font-size: 16px;
  line-hight: 24px;
  color: ${colors.textColorSecondary};
`;

export const StyledConflictOfInterestFormCheckbox = styled(Checkbox)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 16px !important;
  color: ${colors.accentOrange};
  .ant-checkbox {
    top: 4px !important;
    .ant-checkbox-inner {
      border-color: ${colors.accentOrange};
    }
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: ${colors.accentOrange};
      }
    }
  }
`;

export const StyledConflictDisclosure = styled(Button)`
  color: ${colors.white};
  background-color: ${colors.orangeShadow};
  border-color: ${colors.orangeShadow};
  padding: 4px 25px;

  & > .anticon + span,
  & > span + .anticon {
    margin-left: 8px;
    margin-right: 8px;
  }

  &:hover,
  &:focus {
    color: ${colors.white};
    background-color: ${colors.orange};
    border-color: ${colors.orange};
  }
`;

export const StyledConflictOfInterestFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledFooterNote = styled(Paragraph)`
  margin: 0 !important;
  font-size: 12px;
  font-weight: 400;
  color: ${colors.lightGrey};
`;

import { Modal } from 'antd';
import styled from 'styled-components';

export const StyledConflictOfInterestPopup = styled(Modal)`
  width: 878px !important;
  padding-bottom: 0;
  .ant-modal-content {
    border-radius: 4px;
    .ant-modal-body {
      padding: 24px;
    }
  }
`;

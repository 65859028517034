import React, { FC, useCallback } from 'react';
import { useSubmitConflictOfInterest, useSubmitEthicsCodeConfirmation } from 'api';
import { notification } from 'antd';
import { EModalToOpen, IConflictOfInterestFormFields } from 'types';
import { getConflictOfInerestPayload } from 'ui';
import { useTranslate } from 'translations';
import { ModalContext } from './ModalProviderContext';
import { useModalToOpen } from './hooks';

export const ModalContextProvider: FC = ({ children }) => {
  const { setModalToOpen, profileData, modalToOpen } = useModalToOpen();
  const { t } = useTranslate();
  const {
    mutateAsync: submitEthicsCodeConfirmation,
    isLoading: isSubmitEthicsCodeConfirmationLoading,
  } = useSubmitEthicsCodeConfirmation();
  const {
    mutateAsync: submitConflictOfInerest,
    isLoading: isSubmitConflictOfInterestLoading,
  } = useSubmitConflictOfInterest();

  const notifyError = useCallback(() => {
    notification.error({
      message: t('messages.error.wentWrong'),
      description: t('messages.error.tryAgainLater'),
    });
  }, [t]);
  const onSubmitCodeOfEthics = useCallback(
    ({ confirm }: { confirm: boolean }) => {
      submitEthicsCodeConfirmation(confirm)
        .then(() => {
          if (profileData?.isConflictOfInterestRequired) {
            setModalToOpen(EModalToOpen.CONFLICT_OF_INTEREST);
          } else {
            setModalToOpen(EModalToOpen.NOT_ANY);
          }
        })
        .catch(() => {
          notifyError();
        });
    },
    [
      notifyError,
      profileData?.isConflictOfInterestRequired,
      setModalToOpen,
      submitEthicsCodeConfirmation,
    ]
  );

  const onSubmitConflictOfInterest = useCallback(
    async (formFields: IConflictOfInterestFormFields) => {
      try {
        const formData = getConflictOfInerestPayload(formFields);
        await submitConflictOfInerest(formData);
        setModalToOpen(EModalToOpen.NOT_ANY);
      } catch (ex) {
        notifyError();
      }
    },
    [notifyError, setModalToOpen, submitConflictOfInerest]
  );

  return (
    <ModalContext.Provider
      value={{
        openModal: modalToOpen,
        onSubmitCodeOfEthics,
        isSubmitEthicsCodeConfirmationLoading,
        onSubmitConflictOfInterest,
        isSubmitConflictOfInterestLoading,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

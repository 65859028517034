import { createContext, useContext } from 'react';
import { noop } from 'rxjs';
import { EModalToOpen, IModalContext } from 'types';

const defaultModalContextValue: IModalContext = {
  openModal: EModalToOpen.NOT_ANY,
  onSubmitCodeOfEthics: noop,
  isSubmitEthicsCodeConfirmationLoading: false,
  onSubmitConflictOfInterest: noop,
  isSubmitConflictOfInterestLoading: false,
};

export const ModalContext = createContext<IModalContext>(defaultModalContextValue);

export const UseModalContext = () => {
  const modalContext = useContext(ModalContext);
  if (!modalContext) {
    throw new Error('Can not use UseModalContext out of Modal Context provider');
  }
  return modalContext;
};

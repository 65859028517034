import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AppProviders } from 'app/AppProviders';
import { AppRoutes } from 'app/AppRoutes';
import { initHttpClient, initOutBoundHttpClient } from 'api';
import { GlobalStyles } from 'styles';
import './App.less';
import { EthicsCodeDialog, ConflictOfInterest } from 'ui/components';

const { REACT_APP_API_BASE_URL } = process.env;

initHttpClient(REACT_APP_API_BASE_URL as string);
initOutBoundHttpClient();

export const App = () => (
  <>
    <GlobalStyles />
    <BrowserRouter>
      <AppProviders>
        <AppRoutes />
        <EthicsCodeDialog />
        <ConflictOfInterest />
      </AppProviders>
    </BrowserRouter>
  </>
);

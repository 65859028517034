import { RcFile } from 'antd/lib/upload';
import { TType } from 'translations';
import { FormFieldsOptions, IConflictOfInterestFormFields, IQuestionLayoutValues } from 'types';

export const getQuestionItems = (
  t: TType,
  membersOfFamily: FormFieldsOptions[],
  typeOfReporting: FormFieldsOptions[]
): IQuestionLayoutValues => {
  return [
    {
      questionHeader: t('conflict.of.interest.disclosure.first.question'),
      radioFieldName: 'typeOfReporting',
      radioKey: 'typeOfReportingKey',
      radioProps: typeOfReporting,
      radioGroupName: 'groupNameTypeOfReporting',
      actualRadioKey: 'radioKeyDislosure',
      uploadFormFieldName: 'filesTypeOfReporting',
      restrictedUploadKey: 'restrictedUploadKeyOne',
      DragWatermarkKey: 'waterMarkKeyOne',
    },
    {
      questionHeader: t('conflict.of.interest.disclosure.second.question'),
      radioFieldName: 'typeOfDisclosure',
      radioKey: 'DisclosureKey',
      radioProps: membersOfFamily,
      radioGroupName: 'gruouNameDisclosure',
      actualRadioKey: 'radioKeyInterest',
      uploadFormFieldName: 'filesConflictOfInterest',
      restrictedUploadKey: 'restrictedUploadKeyTwo',
      DragWatermarkKey: 'waterMarkKeyTwo',
      showExplain: true,
    },
  ];
};

export const typeOfDisclosure = {
  CHANGE_DISCLOSURE: '1',
  NO_CHANGE_DISCLOSURE: '2',
  INITIAL_DISCLOSURE: '3',
  YES: '4',
  NO: '5',
};

export const ALLOWED_FILE_EXT =
  '.doc, .docx, .ppt, .pptx, .png, .jpg, .jpeg, .xls,. xlsx, .pdf, .gif, .msg, .txt, .csv, .mp4, .heic, .mov, .mkv, .3gp, .ppt, .pptx';

export const getConflictOfInerestPayload = (
  formFields: IConflictOfInterestFormFields
): FormData => {
  const formData = new FormData();
  formData.append('typeOfReportingId', formFields.typeOfReporting);
  formData.append('membersOfFamilyId', formFields.typeOfDisclosure);
  if (formFields.explaination)
    formData.append('membersOfFamilyExplanation', formFields.explaination.replaceAll('\n', ' '));
  if (formFields.filesTypeOfReporting && formFields.filesTypeOfReporting.length > 0) {
    formData.append(
      'typeOfReportingAttachment.file',
      formFields.filesTypeOfReporting[0].originFileObj as RcFile
    );
  }
  if (formFields.filesConflictOfInterest && formFields.filesConflictOfInterest.length > 0) {
    formData.append(
      'membersOfFamilyAttachment.file',
      formFields.filesConflictOfInterest[0].originFileObj as RcFile
    );
  }
  return formData;
};

import { Radio } from 'antd';
import styled from 'styled-components';
import { colors } from 'ui/elements';

export const StyledRadio = styled(Radio)`
  display: flex;
  align-items: center;
  .ant-radio {
    &-checked {
      .ant-radio-inner {
        border-color: ${colors.orange};

        &:after {
          background: ${colors.orange};
        }
      }
    }
  }
  > span {
    &:last-child {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: ${colors.textColorSecondary};
    }
  }
`;
